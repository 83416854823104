import { ItemCell, Label, NoteIcon, OrderContainer, OrderItem, StyledLink } from './styledComponents';

import { FormattedMessage } from 'react-intl';
import Money from '../Money/Money';
import Notes from './Notes.svg';
import { Popover } from 'antd';
import PriceByStatus from './PriceByStatus';
import React, { FC } from 'react';
import messages from 'containers/OrdersPage/messages';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import ItemQuantity from './ItemQuantity';
import generateProductSlug from 'utils/generateProductSlug';
import { Order } from 'types/schema';
import { getUomNameLocal } from 'utils/getUomNameLocal';

interface DesktopTableProps {
  order: Order;
}

const Note = styled.p`
  color: #8c8c8c;
`;

const DesktopTable: FC<DesktopTableProps> = (props) => {
  const { order } = props;
  const lang = localStorage.getItem('lang') || 'vi';
  return (
    <OrderContainer>
      <OrderItem className="header">
        <ItemCell key="Product" fbasis="50%">
          <FormattedMessage {...messages.product} />
        </ItemCell>
        <ItemCell key="Price" fbasis="15%">
          <FormattedMessage {...utilsMessages.price} />
        </ItemCell>
        <ItemCell key="Quantity" fbasis="15%">
          <FormattedMessage {...utilsMessages.quantity} />
        </ItemCell>
        <ItemCell key="Total" fbasis="15%">
          <FormattedMessage {...utilsMessages.totalInclVAT} />
        </ItemCell>
        <ItemCell key="Notes" fbasis="5%" />
      </OrderItem>
      {order.items.map((item, index) => {
        const {
          product: { id, vat, uomLocal, name, supplierInternalCode },
          buyerNote,
          orderedPrice,
          price,
          orderedQuantity,
          deliveryQuantity,
          total,
          deliveredNote,
        } = item;

        return (
          <OrderItem key={id}>
            <ItemCell key="Product" fbasis="50%">
              <StyledLink title={name} to={`/products/${generateProductSlug(name, supplierInternalCode, lang)}`}>
                {name}
              </StyledLink>
              {buyerNote && <Note>{buyerNote}</Note>}
            </ItemCell>
            <ItemCell key="Price" fbasis="15%">
              <PriceByStatus orderedPrice={orderedPrice || ''} deliveredPrice={price || ''} status={order.status} />
            </ItemCell>
            <ItemCell key="Quantity" fbasis="15%">
              <ItemQuantity
                orderedQuantity={Number(orderedQuantity)}
                deliveryQuantity={Number(deliveryQuantity)}
                uom={getUomNameLocal(uomLocal || '')}
              />
            </ItemCell>
            <ItemCell key="Total" fbasis="15%">
              <span>
                <Money>{total || 0}</Money>
                <br />
                <Label>( {vat}% VAT )</Label>
              </span>
            </ItemCell>
            <ItemCell key="Notes" fbasis="5%">
              {deliveredNote && (
                <Popover content={<pre>{deliveredNote}</pre>}>
                  <NoteIcon src={Notes} note="true" />
                </Popover>
              )}
            </ItemCell>
          </OrderItem>
        );
      })}
    </OrderContainer>
  );
};

export default DesktopTable;

import { DownOutlined, PrinterOutlined, UpOutlined } from '@ant-design/icons';
import { Col, Popover, Row, Divider, Modal as AModal, message, Tooltip } from 'antd';
import { ExpandIcon, Label, NoteIcon } from './styledComponents';
import React, { Fragment } from 'react';

import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Money from '../Money/Money';
import Notes from './Notes.svg';
import OrderStatus from '../OrderStatus';
import { getPermissionsActive } from 'utils/permissionMap';
import media from 'utils/mediaStyle';
import messages from 'containers/OrdersPage/messages';
import moment from 'moment';

import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import { OrderType } from 'types/schema';
import translations from 'translations';
import { createStructuredSelector } from 'reselect';
import { selectIsFreeshipOffer, selectVaInfo } from 'containers/MainLayout/selectors';
import { RootState } from 'containers/OrderDetailsPage/types';
import { connect } from 'react-redux';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import Info from './Info.svg';
import CopyIcon from './Copy.svg';
import KamereoBankQr from './kamereoBankQr.png';
import { Icon } from '@ant-design/compatible';

export const InfoRow = styled.div`
  margin-bottom: 4pt;
  display: flex;
  flex-direction: row;
`;

const TotalLabel = styled.span`
  color: rgba(0, 0, 0, 0.54);
  min-width: 80pt;
  margin-right: 10pt;
  ${media.md`
    width: 100%;
    margin-left: 8pt;
    text-align: right;
  `};
`;

const LabelBtn = styled.span`
  color: #f5222d;
  min-width: 80pt;
  cursor: pointer;
  text-align: right;
  margin-left: auto;
`;
const SupplierName = styled.h3``;

const Value = styled.span`
  color: ${({ color }) => (color ? color : 'rgba(0, 0, 0, 0.87)')};
  flex: 1;
  > * {
    display: inline-block;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  margin-top: 10px;
  ${Button} {
    margin-bottom: 8pt;
    width: 100%;
    ${media.md`
      width: auto;
      margin-left: 8pt;
    `};
  }
`;

const Price = styled.span`
  font-size: 16px;
  text-align: right;
  display: block;
`;

const ReDeliveryOrder = styled.span`
  min-width: 80pt;
  margin-bottom: 10px;
  font-size: 12px;
`;
const DeliveryPolicy = styled(Row)`
  margin-left: -8pt;
  margin-right: -8pt;
  .ant-divider {
    margin-top: 6px;
    margin-bottom: 8pt;
  }
  span {
    margin: 0 8pt;
    white-space: pre-line;
  }
`;

const TransferInstruction = styled.div`
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}; width: 100% !important`}
  position: relative;
  display: flex;
  gap: 4px;
  align-items: flex-start;
  background: #fffbeb;
  border-radius: 8px;
  padding: 10px 8px;
  margin-bottom: 6px;
  @media (min-width: 1024px) {
    width: 383px;
  }
`;

const TransferInstructionTextbox = styled.div`
  font-size: 14px;
  color: #242527;
`;
const TransferInstructiontNoteText = styled.div`
  color: #43a047;
  cursor: pointer;
`;

const Modal = styled(AModal)`
  max-width: 536px !important;
  width: 100% !important;
  padding: 0 16px;
  .ant-modal-footer {
    display: none;
  }
`;

const TransferInstructionTitle = styled.div`
  text-align: center;
`;
const QrWrapper = styled.div`
  flex-shrink: 0;
  width: 144px;
  background: #f6f6f6;
  border-radius: 8px;
  padding: 10px;
`;
const QrTitle = styled.div`
  font-size: 14px;
  color: #808289;
  text-align: center;
`;
const QrImage = styled.img`
  width: 124px;
  height: 124px;
  margin-top: 4px;
`;
const AccountInfoWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 16px;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
`;
const AccountInfoTextBox = styled.div`
  width: 100%;
`;
const AccountInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 7px;
  }
`;
const AccountInfoTextWrapper = styled.div``;
const AccountInfoLabel = styled.div`
  color: #808289;
  font-size: 14px;
`;
const AccountInfoContent = styled.div`
  color: #242527;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 4px;
`;
const SeparateLine = styled.div`
  margin: 24px 0;
  height: 1px;
  width: 100%;
  background: #d9d9d9;
`;
const ImportantNoteTitle = styled.div`
  color: #242527;
  font-size: 14px;
  font-weight: 600;
`;
const ImportantNoteText = styled.div`
  color: #242527;
  font-size: 14px;
  font-weight: 400;
`;

const Copy = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;
const CopyText = styled.div`
  color: #43a047;
  font-size: 14px;
`;

interface IStateProps {
  isFreeshipOffer: boolean;
  vaInfo: any;
}

interface OrderSummaryProps extends IStateProps {
  isMobileSize: boolean;
  order: any;
  onCancelOrder: () => void;
  reOrderAll: () => void;
  onApproveOrder: () => void;
  onDisapproveOrder: () => void;
  onEditOrder: () => void;
  onUpdateDelivery: (data: any) => void;
  permissions: string[];
}

const componentPermissions = {
  addItemToCart: 'ORDER_PLACE',
  editPriceOrder: 'ORDER_ITEM_SET_PRICE',
  editQuantityOrder: 'ORDER_ITEM_SET_QUANTITY',
  confirmDeliveryOrder: 'ORDER_DELIVERY_CONFIRM',
  cancelOrder: 'ORDER_CANCEL',
};

const CopyElement = (text) => {
  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success(translations(messages.copySuccess));
      })
      .catch(() => {
        message.error(translations(messages.copyFail));
      });
  };
  return (
    <Copy onClick={() => copy(text)}>
      <SVGIcon src={CopyIcon} />
      <CopyText>
        <FormattedMessage {...messages.copy} />
      </CopyText>
    </Copy>
  );
};

class OrderSummary extends React.Component<OrderSummaryProps> {
  public state = { expandOrder: false, isShowTransferPopup: false };

  public onExpandOrder = () => {
    if (this.props.isMobileSize) {
      this.setState({ expandOrder: !this.state.expandOrder });
    }
  };

  public print = () => {
    const { order } = this.props;
    const date = moment(order.orderDate).utcOffset(7);
    const orderDateKey = date.format('YYYY-MM-DD');

    const lang = localStorage.getItem('lang') || 'vi';
    const env = (process.env.APP_ENV || 'qa').toLowerCase().replace('development', 'qa');
    const url = `https://s3-ap-southeast-1.amazonaws.com/print.kamereo.vn/${env}/orders-pdf/${orderDateKey}/${order.id}-BUYER-${lang}.pdf`;
    window.open(url, '_blank');
  };

  public orderType = (orderType) => {
    switch (orderType) {
      case OrderType.Gift:
        return translations(messages.giftOrder);
      case OrderType.Sample:
        return translations(messages.sampleOrder);
      case OrderType.Redelivery:
        return translations(messages.redeliveryOrder);
      case OrderType.Employee:
        return translations(messages.employeeOrder);
      default:
        return '';
    }
  };

  public orderForDependOnOrderType = (order: any) => {
    switch (order.orderType) {
      case OrderType.Employee:
      case OrderType.Gift:
      case OrderType.Sample:
        return order.zeroOrderInfo.receiver;
      case OrderType.Redelivery:
        return order.zeroOrderInfo.orderId;
      case OrderType.PaidOrder:
        return '';
      default:
        return 'Chưa xác định được';
    }
  };

  public render() {
    const { order, permissions, onApproveOrder, onEditOrder, onDisapproveOrder, vaInfo } = this.props;
    const orderDate = moment(order.orderDate);
    const printShouldBeReady: boolean = orderDate.isBefore(moment().subtract(1, 'minutes'));
    const isExpandOrder = this.props.isMobileSize ? this.state.expandOrder : true;
    const canApproveOrder = getPermissionsActive(['ORDER_APPROVE'], permissions);
    const bankAccount = {
      accountName: vaInfo?.vaName || 'CÔNG TY TNHH KAMEREO',
      accountNumber: vaInfo.vaAccountNumber || '137483',
      bank: 'NGÂN HÀNG PHƯƠNG ĐÔNG (OCB) - CN TP.HCM',
      qrCode: vaInfo?.qrDataBase64 || KamereoBankQr,
    };
    const transferNotes = [
      <FormattedMessage key="1" {...messages.firstImportantNote} />,
      <FormattedMessage key="2" {...messages.secondImportantNote} />,
      <FormattedMessage key="3" {...messages.thirdImportantNote} />,
    ];
    return (
      <Fragment>
        <Row gutter={8} style={{ marginBottom: '10pt' }} onClick={this.onExpandOrder}>
          <Col span={24}>
            <InfoRow style={{ width: '100%', justifyContent: 'space-between' }}>
              <Link to={`/suppliers/${encodeURIComponent(order.supplier.name)}`}>
                <SupplierName>{order.supplier.name}</SupplierName>
              </Link>
              {this.props.isMobileSize
                ? null
                : order &&
                  order.status !== 'PENDING_APPROVAL' && (
                    <Button
                      type="primary"
                      ghost
                      onClick={this.print}
                      disabled={!printShouldBeReady}
                      title={printShouldBeReady ? '' : <FormattedMessage {...utilsMessages.printing} />}
                    >
                      <PrinterOutlined /> <FormattedMessage {...utilsMessages.print} />
                    </Button>
                  )}
            </InfoRow>
            <InfoRow>
              <OrderStatus status={order.status} statusText={order.statusText} />
            </InfoRow>
            {this.props.isMobileSize && (
              <ExpandIcon>{this.state.expandOrder ? <UpOutlined /> : <DownOutlined />}</ExpandIcon>
            )}
          </Col>
        </Row>
        <Row gutter={8}>
          {isExpandOrder && (
            <Fragment>
              <Col lg={9} sm={12}>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...utilsMessages.orderId} />
                  </Label>
                  <Value style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{order.id}</Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.orderTime} />
                  </Label>
                  <Value>{moment(order.orderDate).format('HH:mm DD/MM/YYYY')}</Value>
                </InfoRow>
                {order.orderType !== OrderType.PaidOrder && (
                  <InfoRow>
                    <Label>
                      <FormattedMessage {...messages.orderType} />
                    </Label>
                    <Col>
                      <Row>
                        <p style={{marginRight: 10}}>{this.orderType(order.orderType)}</p>
                        {order.zeroOrderInfo?.reason && (
                          <Tooltip title={order.zeroOrderInfo?.reason} placement="topLeft" style={{ marginLeft: 20 }}>
                            <Icon type="exclamation-circle" />
                          </Tooltip>
                        )}
                      </Row>
                      <p>{this.orderForDependOnOrderType(order)}</p>
                    </Col>
                  </InfoRow>
                )}
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.deliveryTime} />
                  </Label>
                  <Value>
                    {moment(order.deliveryTime.start).format('HH:mm')}-{moment(order.deliveryTime.end).format('HH:mm')}{' '}
                    {moment(order.deliveryTime.end).format('DD/MM/YYYY')}
                  </Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.creator} />
                  </Label>
                  <Value>{order.creator.name}</Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.storeName} />
                  </Label>
                  <Value>{order.store.name}</Value>
                </InfoRow>
              </Col>
              <Col lg={8} sm={12}>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...utilsMessages.paymentMethod} />
                  </Label>
                  <Value>
                    <FormattedMessage {...utilsMessages[order.paymentMethod || 'COD']} />
                  </Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.totalItems} />
                  </Label>
                  <Value>{order.items.length}</Value>
                </InfoRow>
                <InfoRow>
                  <Label>
                    <FormattedMessage {...messages.notes} />
                  </Label>
                  <Value>
                    {order.note ? (
                      <Popover content={<pre>{order.note}</pre>}>
                        <NoteIcon src={Notes} note="true" />
                      </Popover>
                    ) : (
                      <NoteIcon src={Notes} />
                    )}
                  </Value>
                </InfoRow>
                {(order.status === 'ACCEPTED' ||
                  order.status === 'PENDING_DELIVERY' ||
                  order.status === 'DELIVERING') && (
                  <TransferInstruction>
                    <SVGIcon src={Info} style={{ marginTop: '3px' }} />
                    <TransferInstructionTextbox>
                      <div>
                        <FormattedMessage {...messages.transferNote} />
                      </div>
                      <TransferInstructiontNoteText
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            isShowTransferPopup: !this.state.isShowTransferPopup,
                          });
                        }}
                      >
                        <FormattedMessage {...messages.transferInstruction} />
                      </TransferInstructiontNoteText>
                    </TransferInstructionTextbox>
                  </TransferInstruction>
                )}
                {order.status === 'DECLINED' ? (
                  <InfoRow>
                    <Label>
                      <FormattedMessage {...messages.declineReason} />
                    </Label>
                    <Value>{order.declineReason}</Value>
                  </InfoRow>
                ) : null}
              </Col>
            </Fragment>
          )}
          <Col lg={7} sm={24}>
            <InfoRow>
              <TotalLabel>
                <FormattedMessage {...messages.total} />
              </TotalLabel>
              <Value>
                <Price>
                  <Money>{order.total}</Money>
                </Price>
              </Value>
            </InfoRow>
            {order.orderType !== OrderType.PaidOrder && (
              <Row justify="end">
                <ReDeliveryOrder>{`(${this.orderType(order.orderType)})`}</ReDeliveryOrder>
              </Row>
            )}
            {order.status !== 'PENDING_APPROVAL' && (
              <>
                <InfoRow>
                  {permissions.includes(componentPermissions.cancelOrder) &&
                    order.status &&
                    (order.status.includes('SENT') || order.status.includes('PENDING')) && (
                      <LabelBtn onClick={this.props.onCancelOrder}>
                        <FormattedMessage {...messages.cancelOrder} />
                      </LabelBtn>
                    )}
                </InfoRow>
                <Actions>
                  {permissions.includes(componentPermissions.addItemToCart) && (
                    <Button type="primary" ghost onClick={this.props.reOrderAll}>
                      <FormattedMessage {...utilsMessages.addAll} />
                    </Button>
                  )}
                </Actions>
              </>
            )}
            {order.status === 'PENDING_APPROVAL' && canApproveOrder && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  type="danger"
                  ghost
                  style={{ borderColor: 'transparent', paddingLeft: 0, paddingRight: 0 }}
                  onClick={onDisapproveOrder}
                >
                  <FormattedMessage {...messages.declineThisOrder} />
                </Button>
                <Actions>
                  <Button type="primary" ghost onClick={onEditOrder}>
                    <FormattedMessage {...utilsMessages.edit} />
                  </Button>
                  <Button type="primary" onClick={onApproveOrder}>
                    <FormattedMessage {...utilsMessages.approve} />
                  </Button>
                </Actions>
              </div>
            )}
          </Col>
        </Row>
        <DeliveryPolicy>
          {!this.props.isFreeshipOffer && order.total < 400000 && (
            <>
              <Divider />
              <FormattedMessage {...utilsMessages.deliveryPolicyMessage} />
              <FormattedMessage
                {...utilsMessages.shippingFeePolicyMessage}
                values={{
                  thres: '400,000đ',
                  fee: '25,000đ',
                }}
              />
            </>
          )}
        </DeliveryPolicy>
        <Modal
          open={this.state.isShowTransferPopup}
          closeIcon={true}
          centered={true}
          destroyOnClose={true}
          maskClosable={true}
          onCancel={() => {
            this.setState({
              ...this.state,
              isShowTransferPopup: !this.state.isShowTransferPopup,
            });
          }}
        >
          <TransferInstructionTitle>{translations(messages.transferInstructions)}</TransferInstructionTitle>
          <AccountInfoWrapper>
            {vaInfo.vaAccountNumber ? (
              !!vaInfo?.qrDataBase64 && (
                <QrWrapper>
                  <QrTitle>
                    <FormattedMessage {...messages.qrCode} />
                  </QrTitle>
                  <QrImage src={bankAccount.qrCode} />
                </QrWrapper>
              )
            ) : (
              <QrWrapper>
                <QrTitle>
                  <FormattedMessage {...messages.qrCode} />
                </QrTitle>
                <QrImage src={bankAccount.qrCode} />
              </QrWrapper>
            )}
            <AccountInfoTextBox>
              <AccountInfoRow>
                <AccountInfoTextWrapper>
                  <AccountInfoLabel>
                    <FormattedMessage {...messages.accountName} />
                  </AccountInfoLabel>
                  <AccountInfoContent>{bankAccount.accountName}</AccountInfoContent>
                </AccountInfoTextWrapper>
              </AccountInfoRow>
              <AccountInfoRow>
                <AccountInfoTextWrapper>
                  <AccountInfoLabel>
                    <FormattedMessage {...messages.accountNumber} />
                  </AccountInfoLabel>
                  <AccountInfoContent>{bankAccount.accountNumber}</AccountInfoContent>
                </AccountInfoTextWrapper>
                {CopyElement(bankAccount.accountNumber)}
              </AccountInfoRow>
              <AccountInfoRow>
                <AccountInfoTextWrapper>
                  <AccountInfoLabel>
                    <FormattedMessage {...messages.bank} />
                  </AccountInfoLabel>
                  <AccountInfoContent>{bankAccount.bank}</AccountInfoContent>
                </AccountInfoTextWrapper>
              </AccountInfoRow>
            </AccountInfoTextBox>
          </AccountInfoWrapper>
          <SeparateLine />
          <AccountInfoRow>
            <AccountInfoTextWrapper>
              <AccountInfoLabel>
                <FormattedMessage {...messages.totalAmount} />
              </AccountInfoLabel>
              <AccountInfoContent>
                <Money>{order.total}</Money>
              </AccountInfoContent>
            </AccountInfoTextWrapper>
            {CopyElement(order.total)}
          </AccountInfoRow>
          <AccountInfoRow>
            <AccountInfoTextWrapper>
              <AccountInfoLabel>
                <FormattedMessage {...messages.tranferMessage} />
              </AccountInfoLabel>
              <AccountInfoContent>{order.id}</AccountInfoContent>
            </AccountInfoTextWrapper>
            {CopyElement(order.id)}
          </AccountInfoRow>
          <TransferInstruction marginTop="14px">
            <SVGIcon src={Info} style={{ marginTop: '3px' }} />
            <TransferInstructionTextbox>
              <ImportantNoteTitle>
                <FormattedMessage {...messages.importantNotes} />
              </ImportantNoteTitle>
              {transferNotes
                .filter((_, index) => (order.total > 400000 && index !== 1) || order.total < 400000)
                .map((note, idx) => (
                  <ImportantNoteText key={idx}>
                    {`${idx + 1}. `}
                    {note}
                  </ImportantNoteText>
                ))}
              {/* <ImportantNoteText>
                1. <FormattedMessage {...messages.firstImportantNote} />
              </ImportantNoteText>
              <ImportantNoteText>
                2. <FormattedMessage {...messages.secondImportantNote} />
              </ImportantNoteText> */}
            </TransferInstructionTextbox>
          </TransferInstruction>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, IStateProps>({
  isFreeshipOffer: selectIsFreeshipOffer(),
  vaInfo: selectVaInfo(),
});

export default connect(mapStateToProps)(OrderSummary);

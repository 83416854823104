import SVGIcon from '../SVGIcon/SVGIcon';
import styled from 'styled-components';

export const getStatusColor = status => {
  switch (status) {
    case 'PENDING_ACCEPTANCE':
      return '#D4B106';
    case 'PENDING_APPROVAL':
      return '#E56910';
    case 'APPROVAL_DISAPPROVED':
      return '#808289';
    case 'ACCEPTED':
      return '#2998BD';
    case 'CANCELED':
      return '#808289';
    case 'DECLINED':
      return '#E2483C';
    case 'SENT':
      return '#E3B205';
    case 'IN_PROGRESS':
      return '#64B9FF';
    case 'PENDING_DELIVERY':
      return '#1E7AFD';
    case 'DELIVERING':
      return '#1E7AFD';
    case 'DELIVERED':
      return '#43A047';
    default:
      return '#E8EBF2';
  }
};

export const StatusIcon = styled(SVGIcon)<any>`
  width: 26px;
  height: 26px;
  color: ${({ status }) => getStatusColor(status)};
`;

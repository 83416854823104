/**
 *
 * OrderDetailsPage
 *
 */

import { Dispatch, compose } from 'redux';
import React, { Fragment } from 'react';
import { Viewport } from 'components/ViewportProvider';
import {
  approveOrder as approveOrderActions,
  cancelOrderSubmit,
  confirmOrderSubmit,
  disapproveOrder as disapproveOrderActions,
  reOrderSubmit,
  updateDeliverySubmit,
  updateOrder as updateOrderActions,
} from './actions';
import { selectBuyer, selectStore, selectUserPermissions } from 'containers/MainLayout/selectors';

import Edit from './Edit.svg';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import Modal from 'components/Modal/Modal';
import OrderComponent from 'components/Order/Order';
import OrderItemTable from './OrderItemTable';
import PageHeader from 'components/PageHeader/PageHeader';
import { RootState } from './types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import selectOrderDetailsPage from './selectors';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import EditOrderItemMobile from './EditOrderItemMobile';
import { UnauthorizedComponent } from 'containers/NotAuthorizedPage';

interface OwnProps {
  orderdetailspage: any;
  store: any;
  buyer: any;
  permissions: string[];
}

interface StateProps {}

interface DispatchProps {
  onCancelOrder: (value: any) => void;
  onApproveOrder: (value: any) => void;
  onDisapproveOrder: (value: any) => void;
  onReOrderAll: (value: any) => void;
  onConfirmOrder: (value: any) => void;
  onUpdateDelivery: (value: any) => void;
  onUpdateOrder: (value: any) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class OrderDetailsPage extends React.PureComponent<Props> {
  public state: {
    isOpenEditModal: boolean;
    deletedOrderItems: object;
    editedOrderItems: object;
  } = {
    isOpenEditModal: false,
    deletedOrderItems: {},
    editedOrderItems: {},
  };

  public onOpenEditModal = () => {
    this.setState({
      isOpenEditModal: true,
    });
  };

  public onCloseEditModal = () => {
    this.setState({
      isOpenEditModal: false,
      deletedOrderItems: {},
      editedOrderItems: {},
    });
  };

  public onSubmitSaveQuantity = () => {
    this.props.onUpdateOrder({
      orderId: this.props.orderdetailspage.order.id,
      editedOrderItems: this.state.editedOrderItems,
      deletedOrderItems: this.state.deletedOrderItems,
    });
    this.onCloseEditModal();
  };

  public onSetItemQuantity = (data: { itemId: string; quantity: number }) => {
    this.setState({
      editedOrderItems: {
        ...this.state.editedOrderItems,
        [data.itemId]: data.quantity || 0.001,
      },
    });
  };

  public onDeleteItem = (productId) => {
    this.state.deletedOrderItems[productId] = true;
    this.setState({ deletedOrderItems: { ...this.state.deletedOrderItems } });
  };

  public render() {
    const { isOpenEditModal, editedOrderItems, deletedOrderItems } = this.state;
    const {
      orderdetailspage: { order },
      onCancelOrder,
      onApproveOrder,
      onDisapproveOrder,
      onReOrderAll,
      onConfirmOrder,
      onUpdateDelivery,
      permissions,
      store,
      buyer,
    } = this.props;
    if (!store) {
      return null;
    }
    const isAuthorized = buyer.id === order.buyer.id;
    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        {isAuthorized && (
          <>
            <PageHeader>
              <FormattedMessage {...messages.header} />
            </PageHeader>
            <OrderComponent
              order={order}
              onEditOrder={this.onOpenEditModal}
              onCancelOrder={onCancelOrder}
              onApproveOrder={onApproveOrder}
              onDisapproveOrder={onDisapproveOrder}
              onReOrderAll={onReOrderAll}
              onConfirmOrder={onConfirmOrder}
              onUpdateDelivery={onUpdateDelivery}
              permissions={permissions}
            />
            <Modal
              size="large"
              headerLabel={order.supplier.name}
              headerIcon={Edit}
              isOpen={isOpenEditModal}
              onClose={this.onCloseEditModal}
              onSubmit={this.onSubmitSaveQuantity}
              closeLabel={<FormattedMessage {...utilsMessages.cancel} />}
              submitLabel={<FormattedMessage {...utilsMessages.save} />}
            >
              <Viewport.Consumer>
                {({ width }) => {
                  if (width > 992) {
                    return (
                      <OrderItemTable
                        items={(order.items || []).filter((item) => !deletedOrderItems[item.product.id])}
                        editedOrderItems={editedOrderItems}
                        onSetQuantity={this.onSetItemQuantity}
                        onDeleteItem={this.onDeleteItem}
                      />
                    );
                  }

                  return (
                    <EditOrderItemMobile
                      items={(order.items || []).map((item) => ({
                        ...item,
                        isDelete: deletedOrderItems[item.product.id],
                      }))}
                      editedItems={editedOrderItems}
                      onSetQuantity={this.onSetItemQuantity}
                      onDeleteItem={this.onDeleteItem}
                    />
                  );
                }}
              </Viewport.Consumer>
            </Modal>
          </>
        )}
        {!isAuthorized && !order?.loading && <UnauthorizedComponent fullHeight={false} hasCTA={false} />}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  orderdetailspage: selectOrderDetailsPage(),
  store: selectStore(),
  permissions: selectUserPermissions(),
  buyer: selectBuyer(),
});

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onCancelOrder: (data) => dispatch(cancelOrderSubmit(data)),
    onApproveOrder: (data) => dispatch(approveOrderActions.request(data)),
    onDisapproveOrder: (data) => dispatch(disapproveOrderActions.request(data)),
    onReOrderAll: (data) => dispatch(reOrderSubmit(data)),
    onUpdateDelivery: (data) => dispatch(updateDeliverySubmit(data)),
    onConfirmOrder: (data) => dispatch(confirmOrderSubmit(data)),
    onUpdateOrder: (data) => dispatch(updateOrderActions.request(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<OwnProps>({
  key: 'orderDetailsPage',
  reducer: reducer,
});

const withSaga = injectSaga<OwnProps>({ key: 'orderDetailsPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(OrderDetailsPage);
